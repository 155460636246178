.inquiry-box{
    width:200px;
    height:180px;
}

hr{
    border-top-color: #d9d9d9;
    margin: 9px 0 15px;
}

.d-p td, .d-p th {
    padding: 0.625rem 0.3rem !important;
}