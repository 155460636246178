.dropdown {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    height: 100%;
}

.menu-horizontal .dropdown > .menu-item > .menu-toggle::after {
    transform: translateY(-50%) rotate(135deg) !important;
}



.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before{
    color:#fe5104 !important;
    /* background-color:#ef941d !important; */
}
.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before{
    color:#fe5104 !important;
    /* background-color:#ef941d !important; */
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before{
    color:#fe5104 !important;
    /* background-color:#ef941d !important; */
}